// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-packages-brand-development-tsx": () => import("./../src/pages/packages/brand-development.tsx" /* webpackChunkName: "component---src-pages-packages-brand-development-tsx" */),
  "component---src-pages-packages-digital-marketing-and-seo-tsx": () => import("./../src/pages/packages/digital-marketing-and-seo.tsx" /* webpackChunkName: "component---src-pages-packages-digital-marketing-and-seo-tsx" */),
  "component---src-pages-packages-index-tsx": () => import("./../src/pages/packages/index.tsx" /* webpackChunkName: "component---src-pages-packages-index-tsx" */),
  "component---src-pages-packages-social-media-and-content-creation-tsx": () => import("./../src/pages/packages/social-media-and-content-creation.tsx" /* webpackChunkName: "component---src-pages-packages-social-media-and-content-creation-tsx" */),
  "component---src-pages-packages-web-design-and-development-tsx": () => import("./../src/pages/packages/web-design-and-development.tsx" /* webpackChunkName: "component---src-pages-packages-web-design-and-development-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

